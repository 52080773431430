<template>
  <v-select
    :value="value"
    :items="items"
    item-text="name"
    item-value="id"
    :label="label"
    color="teal"
    :disabled="disabled"
    :required="required"
    :dense="dense"
    :rules="rules"
    return-object
    clearable
    @change="change($event)"
  ></v-select>
</template>

<script>
export default {
  name: 'SelectContractorsInWorksStatus',
  props: {
    value: {
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    items() {
      return this.$store.getters.getContractorsInWorksStatus;
    },
  },
  methods: {
    change(val) {
      const value = (val) ? val.id : null;

      this.$emit('change', value);
      this.$emit('input', value);
      this.$emit('inputObject', val);
    },
  },
};
</script>
